var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('div',{staticClass:"profile-body pt-3"},[(_vm.displayAlert == true)?_c('custom-alert',{attrs:{"id":"alert_box","message":_vm.alertMessage},on:{"showedAlert":_vm.resetAlert}}):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 mb-2"},[_c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_vm._m(1),_c('div',[_c('div',{staticClass:"row"},[_vm._m(2),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('button',{staticClass:"form-control",attrs:{"type":"button"},on:{"click":function($event){return _vm.uploadFile()}}},[_vm._v("Upload COI")])])])]),_c('div',{staticClass:"row"},[_vm._m(3),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('button',{staticClass:"form-control",attrs:{"type":"button","disabled":_vm.totalCount==0},on:{"click":function($event){return _vm.downloadZip()}}},[_vm._v("Download COI")])])])])]),_vm._m(4),_vm._m(5)])])])])],1),_c('b-modal',{attrs:{"id":"upload-file","hide-footer":"","title":"Insurance"}},[_c('div',{staticClass:"d-block text-center"},[_c('VueUploadDropImages',{attrs:{"fileTypeError":_vm.fileTypeError},on:{"handleFiles":_vm.handleFiles,"fileTypeError":_vm.fileTypeErrorSetup}})],1),_c('b-button',{staticClass:"mt-3 col-md-2 right",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.handleImages()}}},[_vm._v("Save")])],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('div',{staticClass:"header-title"},[_c('h3',[_vm._v("Insurance")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-2"},[_c('div',{staticClass:"form-group"},[_c('img',{attrs:{"src":"/images/insurance_section.png"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('a',{attrs:{"href":"https://landing.playershealth.com/afsoftball-coi-4/","target":"_blank"}},[_c('button',{staticClass:"form-control",attrs:{"type":"button"}},[_vm._v("Request for COI")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('a',{attrs:{"href":"https://app.playershealthclaims.com/landing/the-alliance-fastpitch-llc","target":"_blank"}},[_c('button',{staticClass:"form-control",attrs:{"type":"button"}},[_vm._v("File a Claim")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_vm._v(" For any insurance-related inquiries, please get in touch with us at "),_c('a',{staticClass:"ml-1",attrs:{"href":"mailto:insurance@thealliancefastpitch.com"}},[_vm._v("insurance@thealliancefastpitch.com")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"table-list col-md-12 mt-2 p-2 table-responsivenes"},[_c('table',{staticClass:"table table-hover",attrs:{"id":"files_table"}},[_c('thead',[_c('tr',[_c('th',[_vm._v("Name")]),_c('th',[_vm._v("Action")])])]),_c('tbody')])])])
}]

export { render, staticRenderFns }